import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../../components/layout"
import { VieSportiveNav } from "../../components/menus/VieSportiveNav"

const Arbitrage: React.FC = () => (
  <Layout>
    <Helmet title="Arbitrage" />
    <div className="container">
      <div className="row mt-3">
        <div className="secondary-col">
          <VieSportiveNav />
        </div>
        <div className="col-md-8 col-lg-9">
          <h1>Arbitrage</h1>
          <br />
          <h2>Règlements officiels</h2>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Document</th>
                <th scope="col">Rév.</th>
                <th scope="col">Lien</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>App - Règlements FFTA</td>
                <td>en cours</td>
                <td>
                  <a href="https://livre-reglements.tiralarc-grand-est.fr/" target="_blank" rel="noopener noreferrer">
                    <span className="icon-link" /> &copy;CRTA GE
                  </a>
                </td>
              </tr>
              <tr>
                <td>Livre des règlements FFTA</td>
                <td>Décembre 2023</td>
                <td>
                  <a href="https://www.ffta.fr/sites/default/files/2023-12/R%C3%A9glements%20Sportifs%20et%20Arbitrage_Version%20D%C3%A9cembre%202023%20Consolid%C3%A9e.pdf" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf (fichier volumineux)
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <h2>Divers</h2>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Document</th>
                <th scope="col">Rév.</th>
                <th scope="col">Lien</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Liste des arbitres</td>
                <td>Mai 2024</td>
                <td>
                  <a href="/vie-sportive/documents/arbitres-2024.pdf" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
              <tr>
                <td>Désignations extérieur 2024</td>
                <td>13 avril 2024</td>
                <td>
                  <a href="/vie-sportive/documents/calendrier-arbitrage-ext-2024-24-04-13.pdf" target="_blank" rel="noopener noreferrer">
                    <span className="icon-download" /> pdf
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
        </div>
      </div>
    </div>
  </Layout>
)

export default Arbitrage
